import React from "react";
import { connect } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";

const mapStateToProps = state => ({
  eggRollSideAvailable: state.eggRollSideAvailable,
});
function comboSideDialog(props) {
  const { closeNoSave, closeSave, open } = props;
  const comboSides = [
    "Spring Roll",
    ...(props.eggRollSideAvailable ? ["Egg Roll"] : []),
    // "Coke",
    // "Diet Coke",
    // "Mountain Dew",
    // "Sprite",
    "Water",
  ];
  return (
    <div>
      <Dialog aria-labelledby="Side Choice" open={open} onClose={closeNoSave}>
        <DialogTitle id="Side Choice">Side Choice</DialogTitle>
        <div>
          <List>
            {comboSides.map(s => (
              <ListItem button onClick={() => closeSave(s)}>
                <ListItemText primary={s} />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    </div>
  );
}

export default connect(mapStateToProps, null)(comboSideDialog);
comboSideDialog.propTypes = {
  closeNoSave: PropTypes.func.isRequired,
  closeSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
